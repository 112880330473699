.indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    font-size: 2.2em;
    font-weight: 200;
    font-size: 30px;
    color: #555;
    user-select: none;
  }

  .dashboard {
    width: 100%;
    background-color: white;
    /* height: 3rem; */
    margin: 0.5rem;
}

.dashboard div{
  flex: 1 ;
  flex-wrap: wrap;
}

  .header {
    text-align: right;
    width: 100%;
    background-color: #d64c42;
    height: 3rem;
    margin-bottom: 1rem;
}

/* .materi{
  background-color: brown;
  display: flex;
  align-items: stretch;
} */
.dataDiri{
  width: 100%;
  align-items: stretch;
  display: flex;
}

.dataDiri > div {
  color: black;
  text-align: center;
  font-size: 20px;
}

.materi{
  width: 100%;
  align-items: stretch;
  display: flex;
}

.materi > div {
  color: black;
  text-align: center;
  font-size: 20px;
}

.nilai{
  width: 100%;
  align-items: stretch;
  display: flex;
}

.nilai > div {
  color: black;
  text-align: center;
  font-size: 20px;
}
